.App {
  text-align: center;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}

.App-logo {
  animation: slide-in infinite 50s linear;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #151515;
}

@-webkit-keyframes slide-in {
  from {
    transform: translateX(200%);
  }
  to {
    transform: translateX(-200%);
  }
}

@keyframes slide-in {
  from {
    transform: translateX(200%);
  }
  to {
    transform: translateX(-200%);
  }
}

/*
  SCALE-IN ANIMATION
  */
/* .App-logo {
  -webkit-animation: scale-in 30s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
    both;
  animation: scale-in 30s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both;
}

@-webkit-keyframes scale-in {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
} */
